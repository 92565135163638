import ProductList from '../components/cart/product-list';
import CartInfo from '../components/cart/cart-info';
import { Col, Empty, Row } from 'antd';
import { useSelector } from 'react-redux';
import { cartItemsReducer, cartLoadingReducer } from '../redux/reducers';
import Loader from '../components/loader';

const Cart = () => {
    const loading = useSelector(cartLoadingReducer);
    const items = useSelector(cartItemsReducer);
    return items.length > 0 ? <>
        <Row className='cart' gutter={[16, 16]}>
            <Col xs={{ flex: '100%' }} sm={{ flex: '50%' }} md={{ flex: '60%' }}>
                <ProductList />
            </Col>
            <Col xs={{ flex: '100%' }} sm={{ flex: '50%' }} md={{ flex: '40%' }}>
                <CartInfo />
            </Col>
        </Row>
        {loading && <Loader />}
    </> : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Empty />
    </div>
}

export default Cart;