import { Button, Card, Col, Row } from "antd"
import Meta from "antd/es/card/Meta";
import { useDispatch, useSelector } from "react-redux";
import { cartTipReducer } from "../../redux/reducers";
import { setTip } from '../../redux/cart-slice';

const ApplyTip = () => {
    const tip = useSelector(cartTipReducer);
    const dispatch = useDispatch();

    return <Card size='small'>
        <Meta
            title='Tip Your Hunger Saviour'
            description='Note:- All tip will directly go to your delivery partner.'
        />
        <Row gutter={[12, 12]} style={{ marginTop: '12px' }}>
            {_tips.map(i => {
                var type = i === tip ? 'primary' : 'default';
                return <Col key={i} span={6}>
                    <Button
                        size='small'
                        type={type}
                        block
                        onClick={() => dispatch(setTip(i))}
                    > रु{i} </Button>
                </Col>
            })}
        </Row>
    </Card>
}

export default ApplyTip;

const _tips = [30, 40, 50, 60, 70, 80, 90, 100];