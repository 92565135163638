import { useLoaderData } from "react-router-dom";
import { useEffect, useState } from "react";
import ProductGrouppedList from "../components/product/product-groupped-list";
import RestaurantInfo from "../components/restaurant/restaurant-info";
import { isRestaurantOpen } from "../utils/utils";

const RestaurantDetail = () => {
    const { restaurant, products } = useLoaderData();
    const [search, setSearch] = useState('');
    const [isOpen, setIsOpen] = useState(true);

    const init = () => {
        if (restaurant) {
            const res = isRestaurantOpen(restaurant['openingTime'], restaurant['closingTime']);
            setIsOpen(res);
        }
    }

    useEffect(() => { init() }, [restaurant]);

    useEffect(() => {
        const timer = window.setInterval(() => { init() }, 60000);
        return () => { window.clearInterval(timer) }
    }, [restaurant]);

    return <div className="restaurant">
        {
            restaurant ? <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                <RestaurantInfo restaurant={restaurant} isOpen={isOpen} />
                <ProductGrouppedList
                    isOpen={isOpen}
                    restaurant={restaurant}
                    products={search.length > 0 ? products.filter(e => e['name'].toLowerCase().includes(search)) : products}
                    setSearch={setSearch}
                />
            </div> : <div>No data found</div>
        }
    </div>
}

export default RestaurantDetail;

/*{restaurant && <div style={{ lineHeight: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '6px' }}>
                    <div>{restaurant['name']}</div>
                    <div>{restaurant['street']}</div>
                </div>}*/