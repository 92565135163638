import { GeoPoint } from "@firebase/firestore";

// get formatted city
export function getFormattedCity(city) {
    if (city.toString().endsWith('ज')) {
        return 'birgunj';
    } else if (city.toString().endsWith('या')) {
        return 'kalaiya';
    } else {
        return city.toLowerCase();
    }
}

// get group by data
export const groupBy = (data, key) => {
    return data.reduce(function (acc, item) {
        (acc[item[key]] = acc[item[key]] || []).push(item);
        return acc;
    }, {});
};

// get formatted date for order
export const getFormattedOrderDate = (date) => {
    const orderedDate = new Date(date);
    const finalDate = orderedDate.toLocaleDateString('en-us', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    });
    return finalDate;
}

// get order status color
export const getOrderStatusColor = (status) => {
    if (status === 'ordered') {
        return 'amber';
    } else if (status === 'confirmed') {
        return 'blue';
    } else if (status === 'accepted') {
        return 'pink';
    } else if (status === 'picked') {
        return 'purple';
    } else if (status === 'delivered') {
        return 'green';
    } else {
        return 'red';
    }
}

// get current time
function _getCurrentTime(date, hour12 = false) {
    return date.toLocaleTimeString('en-us', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: hour12,
    });
}

// formatted time
export const getFormattedTime = (time) => {
    return new Date(`2024-01-01 ${time}`).toLocaleTimeString('en-us', {
        hour: '2-digit',
        hour12: true,
        minute: '2-digit',
    })
}

// is resturant closed
export const isRestaurantOpen = (open, close) => {
    const date = new Date();
    const time = _getCurrentTime(date);
    const current = new Date('01/01/2024 ' + time);
    const openDate = new Date('01/01/2024 ' + open);
    const closeDate = new Date('01/01/2024 ' + close);
    return (current.getTime() >= openDate.getTime() && current.getTime() <= closeDate.getTime());
}

// address geo possition
export async function getAddressGeoPossition(lat, lng) {
    try {
        const possition = new GeoPoint(lat, lng);
        return {
            geohash: '',
            geopoint: possition,
        }
    } catch (error) {
        console.error(error);
    }
}

// get formatted date
export const getFormattedDate = () => {
    var date = new Date();
    var updateDate = date.toLocaleDateString('en-us', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });
    var splited = updateDate.split('/');
    return [splited[2], splited[0], splited[1]].join('-');
}