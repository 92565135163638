import { LockOutlined } from "@ant-design/icons";
import { Form, Input } from "antd";

const PasswordConfirmInput = ({ disabled }) => {
    return <Form.Item
        name="password_confirm"
        dependencies={['password']}
        hasFeedback
        rules={[
            {
                required: true,
                message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
                validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error('The new password that you entered do not match!'));
                },
            }),
        ]}
    >
        <Input.Password prefix={<LockOutlined />} placeholder="Confirm Password" disabled={disabled} />
    </Form.Item>
}

export default PasswordConfirmInput;