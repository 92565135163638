// global
export const globalLoadingReducer = state => state.global.loading;
export const locationLatitudeReducer = state => state.global.latitude;
export const locationLongitudeReducer = state => state.global.longitude;
export const locationAddressReducer = state => state.global.address;
export const locationCityReducer = state => state.global.city;
export const bannersListReducer = state => state.global.banners;
export const categoriesListReducer = state => state.global.categories;
export const restaurantsListReducer = state => state.global.restaurants;
export const searchReducer = state => state.global.search;

// auth
export const currentAuthUserReducer = state => state.auth.user;
export const currentAuthUserDetailReducer = state => state.auth.userDetail;
export const ordersListReducer = state => state.auth.orders;
export const favouritesListReducer = state => state.auth.favourites;
export const addressesListReducer = state => state.auth.addresses;

// cart
export const cartLoadingReducer = state => state.cart.loading;
export const cartSettingsReducer = state => state.cart.settings;
export const cartItemsReducer = state => state.cart.items;
export const cartRestaurantReducer = state => state.cart.restaurant;
export const cartItemsTotalReducer = state => {
    var items = state.cart.items;
    return items.length > 0 ? items.reduce((x, p) => (x + (p['sellingPrice'] * p['quantity'])), 0) : 0;
}
export const cartVoucherReducer = state => state.cart.voucher;
export const cartDeliveryChargeReducer = state => {
    const _settings = state.cart.settings;
    const _deliveryDistance = state.cart.deliveryDistance;
    const items = state.cart.items;
    const subTotal = items.length > 0 ? items.reduce((x, p) => (x + (p['sellingPrice'] * p['quantity'])), 0) : 0;
    const _unCancelledOrders = state.auth.orders.filter(e => e['status'] !== 'cancelled').length;
    const baseDeliveryCharge = _settings !== null ? _settings['baseDeliveryCharge'] : 40;
    const _baseDeliveryKM = _settings !== null ? _settings['baseDeliveryKM'] : 2;
    const _perKM = _settings !== null ? _settings['perKM'] : 25;
    const _freeDelivery = _settings !== null ? _settings['freeDelivery'] : 0;
    const _freeDeliveryKM = _settings !== null ? _settings['freeDeliveryKM'] : 0;
    const _minimumOrder = _settings !== null ? _settings['minimumOrder'] : 499;

    if (_freeDelivery > _unCancelledOrders) {
        if (_deliveryDistance <= _freeDeliveryKM) {
            if (subTotal > _minimumOrder) {
                return 0;
            } else {
                return baseDeliveryCharge;
            }
        } else {
            var remaining = _deliveryDistance - _freeDeliveryKM;
            return (remaining * _perKM) + baseDeliveryCharge;
        }
    } else {
        if (_deliveryDistance > _baseDeliveryKM) {
            var x = _deliveryDistance - _baseDeliveryKM;
            return (x * _perKM) + baseDeliveryCharge;
        } else {
            return baseDeliveryCharge;
        }
    }
}
export const cartDiscountReducer = state => {
    var voucher = state.cart.voucher;
    var items = state.cart.items;
    var subTotal = items.length > 0 ? items.reduce((x, p) => (x + (p['sellingPrice'] * p['quantity'])), 0) : 0;
    if (voucher) {
        if (voucher['type'] === '%') {
            const x = subTotal * (voucher['value'] / 100);
            if (x <= voucher['upto']) {
                return x;
            } else {
                return voucher['upto'];
            }
        }
        return voucher['value'];
    }
    return 0;
};
export const cartTipReducer = state => state.cart.tip;
export const cartDeliveryAddressReducer = state => state.cart.deliveryAddress;
export const cartDeliveryDistanceReducer = state => state.cart.deliveryDistance;