import { useParams } from "react-router-dom";
import RestaurantCard from "../components/restaurant/restaurant-card";
import { restaurantsListReducer } from "../redux/reducers";
import { useSelector } from "react-redux";
import { Empty } from "antd";

const RestaurantByCategory = () => {
    const params = useParams();
    const lists = useSelector(restaurantsListReducer);
    const restaurants = lists.filter(e => e['categories'].includes(params['category']))
    return <div className='home__restaurant__list'>
        {
            restaurants.length > 0 ? restaurants.map(list => <RestaurantCard
                key={list.id}
                restaurant={list}
            />) : <Empty />
        }
    </div>
}

export default RestaurantByCategory;