import { Form, Select } from "antd";
import { genderRules } from "../../utils/input-rules";
const { Option } = Select;

const GenderInput = ({ disabled }) => {
    return <Form.Item name="gender" rules={genderRules}>
        <Select placeholder="Select your gender" disabled={disabled}>
            <Option value={1}>Male</Option>
            <Option value={2}>Female</Option>
        </Select>
    </Form.Item>
}

export default GenderInput;