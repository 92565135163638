import {
    createUserWithEmailAndPassword,
    sendEmailVerification,
    signInWithEmailAndPassword,
    signOut,
    updateProfile
} from 'firebase/auth';
import { auth, db } from '../utils/firebase-config';
import { addDoc, collection, doc, setDoc } from '@firebase/firestore';
import { getAddressGeoPossition } from '../utils/utils';

// login user using email & password
export async function loginService(email, password) {
    try {
        return signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
        console.log(error)
    }
}

// register using email & password
export async function registerService(name, email, gender, mobile, password) {
    try {
        return createUserWithEmailAndPassword(auth, email, password)
            .then(user => {
                if (user.user != null) {
                    const docRef = doc(db, 'users', user.user.uid);
                    var newUser = {
                        'createdAt': '',
                        'deviceToken': null,
                        'email': email,
                        'freeDelivery': 0,
                        'gender': gender,
                        'mobile': mobile,
                        'name': name,
                        'points': 50,
                        'status': true,
                        'updatedAt': null,
                    };
                    console.log(docRef);
                    setDoc(docRef, newUser).then(() => {
                        updateProfile(user.user, { displayName: name });
                        sendEmailVerification(user.user);
                    });
                }
            })
    } catch (error) {
        console.log(error)
    }
}

// logout user
export async function logoutService() {
    try {
        return signOut(auth);
    } catch (error) {
        console.log(error)
    }
}

// add address
export async function addAddressService(uid, type, name, mobile, street, lat, lng) {
    try {
        const possition = await getAddressGeoPossition(lat, lng);
        console.log(possition);
        var data = {
            addressType: type,
            createdAt: new Date().getTime(),
            email: null,
            mobile: mobile,
            name: name,
            position: possition,
            status: true,
            street: street,
            updatedAt: null,
        }
        console.log(data);
        const parentDocRef = doc(db, 'users', uid);
        const subCollectionRef = collection(parentDocRef, 'address');
        return addDoc(subCollectionRef, data).catch(e => { throw new Error(e) });
    } catch (error) {
        console.log(error)
    }
}