const _url = 'https://nominatim.openstreetmap.org';

// get locatio detail by latitude / longitude
export async function getLocationDetail(lat, lng) {
    try {

        return fetch(`${_url}/reverse?lat=${lat}&lon=${lng}&format=jsonv2`)
            .then(res => res.json())
            .then(res => res)
    } catch (error) {
        console.error(error);
    }
}

// get location by search
export async function searchLocationByName(search) {
    try {
        return fetch(`${_url}/search.php?q=${search}&polygon_geojson=1&format=jsonv2`)
            .then(res => res.json())
            .then(res => res)
    } catch (error) {
        console.error(error);
    }
}

// calculate delivery distance
export async function getDeliveryDistanceService(origin, destination) {
    try {
        // https://proxy.cors.sh/
        var url =
            `https://maps.googleapis.com/maps/api/distancematrix/json?destinations=${destination}&origins=${origin}&key=AIzaSyC6ENE5I2cWZ18oH4SneX2A7qe_Trimbl8&mode=walking`;
        const header = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers':
                'Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale',
        };
        return fetch(url)
            .then(res => res.json())
            .then(response => {
                console.log(response)
                if (response.statusCode === 200) {
                    var data = response.body;
                    if (data['status'] === 'OK' &&
                        data['rows'] !== null &&
                        data['rows'].length > 0 &&
                        data['rows'][0]['elements'] !== null &&
                        data['rows'][0]['elements'].length > 0 &&
                        data['rows'][0]['elements'][0]['status'] === 'OK') {
                        var distance = data['rows'][0]['elements'][0]['distance']['value'];
                        return distance / 1000;
                    } else {
                        console.log('Something went wrong while calculating distance.')
                    }
                } else {
                    console.log(response)
                }
            });

    } catch (e) {
        console.error(e)
    }
}