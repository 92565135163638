import { addDoc, collection } from "@firebase/firestore";
import { db } from "../utils/firebase-config"

// submit your query
export async function submitQueryService(uid, name, mobile, message) {
    try {
        var data = {
            'id': uid,
            'name': name,
            'mobile': mobile,
            'message': message,
            'status': true,
            'createdAt': new Date().getTime(),
            'updatedAt': null,
        }
        var colRef = collection(db, 'queries');
        return addDoc(colRef, data);
    } catch (error) {
        console.error(error);
    }
}