import { useSelector } from "react-redux";
import { restaurantsListReducer } from "../../redux/reducers";
import RestaurantCard from "../restaurant/restaurant-card";

const HomeRestaurantList = () => {
    const lists = useSelector(restaurantsListReducer);
    return <div className='home__restaurant__list'>
        {
            lists.map(list => <RestaurantCard
                key={list.id}
                restaurant={list}
            />)
        }
    </div>
}

export default HomeRestaurantList;