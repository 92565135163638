import { useSelector } from "react-redux";
import { restaurantsListReducer, searchReducer } from "../redux/reducers";
import RestaurantCard from "../components/restaurant/restaurant-card";
import { Empty } from "antd";
import { useEffect, useState } from "react";

const Search = () => {
    const search = useSelector(searchReducer);
    const lists = useSelector(restaurantsListReducer);
    const [restaurants, setRestaurants] = useState([]);

    useEffect(() => {
        if (search && search.length > 0) {
            var res = lists.filter(e => e['name'].includes(search));
            setRestaurants(res);
        } else {
            setRestaurants([]);
        }
    }, [search]);

    return <div className='home__restaurant__list'>
        {
            search && search.length > 0 ?
                restaurants.length > 0 ?
                    restaurants.map(list => <RestaurantCard
                        key={list.id}
                        restaurant={list}
                    />) :
                    <Empty /> :
                <div>Search your favourite restaurant</div>
        }
    </div>
}

export default Search;