import React from 'react';
import { Card } from 'antd';
import Meta from 'antd/es/card/Meta';
import CartActionButton from './cart-action-button';
import { useSelector } from 'react-redux';
import { cartItemsReducer, cartRestaurantReducer } from '../../redux/reducers';
const ProductList = () => {
    const items = useSelector(cartItemsReducer);
    const restaurant = useSelector(cartRestaurantReducer);
    return <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
        {
            items.map(item => <Card key={item['id']} size='small' className='cart__item__card'>
                <Meta
                    title={item['name']}
                    description={`${item['quantity']} x रु${item['sellingPrice']} = रु${item['quantity'] * item['sellingPrice']}`}
                    style={{ width: '100%' }}
                />
                <CartActionButton item={item} resturant={restaurant} />
            </Card>)
        }
    </div>
};
export default ProductList;
