import { LockOutlined } from "@ant-design/icons";
import { Form, Input } from "antd";
import { passwordRules } from "../../utils/input-rules";

const PasswordInput = ({ disabled }) => {
    return <Form.Item name="password" rules={passwordRules}>
        <Input.Password prefix={<LockOutlined />} placeholder="Password" disabled={disabled} />
    </Form.Item>
}

export default PasswordInput;