import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { addToCart, removeFromCart } from "../../redux/cart-slice";

const CartActionButton = ({ resturant, item }) => {
    const dispatch = useDispatch();
    return <div className='cart__item__actions'>
        <Button
            type='primary'
            shape='circle'
            icon={<MinusOutlined />}
            size='small'
            onClick={() => dispatch(removeFromCart(item['id']))}
        />
        <div>{item['quantity']}</div>
        <Button
            type='primary'
            shape='circle'
            icon={<PlusOutlined />}
            size='small'
            onClick={() => dispatch(addToCart({
                restaurant: resturant,
                product: item
            }))}
        />
    </div>;
}

export default CartActionButton;