import { Tabs } from "antd";
import { useEffect, useState } from "react";
import { groupBy } from "../../utils/utils";
import ProductList from "./product-list";
import { Input } from 'antd';
const { Search } = Input;

const ProductGrouppedList = ({ isOpen, restaurant, products, setSearch }) => {
    const [items, setItems] = useState([]);
    useEffect(() => {
        var x = groupBy(products, 'category');
        var updated = [];
        for (const [key, value] of Object.entries(x)) {
            updated.push({
                key: key,
                label: `${key}(${value.length})`,
                children: <ProductList isOpen={isOpen} restaurant={restaurant} items={value} />,
            })
        }
        setItems(updated);
    }, [products, restaurant]);
    return <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
        <Search placeholder="input search text" onSearch={(x, y, z) => setSearch(x.toLocaleLowerCase())} />
        <Tabs size='small' defaultActiveKey="1" items={items} />
    </div>
}

export default ProductGrouppedList;