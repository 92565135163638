import { Button, Modal, Tabs, message } from "antd";
import { useState } from "react";
import LoginTab from "./login-tab";
import RegisterTab from "./register-tab";
import { useDispatch, useSelector } from "react-redux";
import { currentAuthUserReducer } from "../../redux/reducers";
import { logoutUser } from "../../redux/auth-slice";
import { logoutService } from "../../services/auth-service";
import { CloseOutlined } from "@ant-design/icons";

const AuthDialog = () => {
    const user = useSelector(currentAuthUserReducer);
    const dispatch = useDispatch();
    const [dialog, setDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    const items = [
        {
            key: '1',
            label: 'Login',
            children: <LoginTab
                loading={loading}
                setLoading={setLoading}
                setDialog={setDialog}
                messageApi={messageApi}
            />,
        },
        {
            key: '2',
            label: 'Register',
            children: <RegisterTab
                loading={loading}
                setLoading={setLoading}
                setDialog={setDialog}
                messageApi={messageApi}
            />,
        }
    ];

    const logout = () => {
        logoutService().then(() => {
            dispatch(logoutUser());
            messageApi.success('Logged out successfully!');
        }).catch(e => {
            messageApi.error(e.message);
        })
    }

    return <>
        {contextHolder}
        {
            user !== null ?
                <Button type="primary" danger onClick={logout}> Logout </Button> :
                <Button type="primary" onClick={() => setDialog(true)}> Login </Button>
        }
        <Modal
            footer={null}
            centered open={dialog}
            confirmLoading={loading}
            destroyOnClose
            className="login__dialog"
            maskClosable
        >
            <Tabs defaultActiveKey="1" items={items} />
            <Button
                size='small'
                type='primary' danger
                shape='circle'
                icon={<CloseOutlined />}
                onClick={() => setDialog(false)}
                style={{ position: 'absolute', top: '24px', right: '24px' }}
            />
        </Modal>
    </>
}

export default AuthDialog;