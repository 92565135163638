import { useSelector } from 'react-redux';
import ContactusInfo from '../components/conatctus-info';
import QueryForm from '../components/query-form';
import { currentAuthUserReducer } from '../redux/reducers';
import AuthDialog from '../components/auth/auth-dialog';

const ContactUs = () => {
    const user = useSelector(currentAuthUserReducer);
    return <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <ContactusInfo />
        {user ? <QueryForm /> : <AuthDialog />}
    </div>
}

export default ContactUs;