import { Button, message } from "antd";
import FooterMenu from "./footer-menu";
import { useSelector } from "react-redux";
import { cartDeliveryAddressReducer, cartDeliveryChargeReducer, cartDeliveryDistanceReducer, cartDiscountReducer, cartItemsReducer, cartItemsTotalReducer, cartRestaurantReducer, cartTipReducer, cartVoucherReducer, currentAuthUserDetailReducer, currentAuthUserReducer } from "../../redux/reducers";
import { getFormattedDate } from "../../utils/utils";

const AppFooter = ({ path }) => {
    const items = useSelector(cartItemsReducer);
    const restaurant = useSelector(cartRestaurantReducer);
    const user = useSelector(currentAuthUserReducer);
    const userDetail = useSelector(currentAuthUserDetailReducer);
    const deliveryAddress = useSelector(cartDeliveryAddressReducer)
    const itemTotal = useSelector(cartItemsTotalReducer);
    const deliveryCharge = useSelector(cartDeliveryChargeReducer);
    const discount = useSelector(cartDiscountReducer);
    const voucher = useSelector(cartVoucherReducer);
    const tip = useSelector(cartTipReducer);
    const deliveryDistance = useSelector(cartDeliveryDistanceReducer);

    const [messageApi, contextHolder] = message.useMessage();

    const placeOrder = () => {
        if (restaurant != null) {
            if (items.length > 0) {
                if (user !== null && userDetail !== null) {
                    if (deliveryAddress !== null) {
                        if (deliveryDistance > 0 && deliveryDistance <= 5) {
                            var time = new Date().getTime();
                            var otp = Math.floor(100000 + Math.random() * 900000);
                            const grandTotal = itemTotal + deliveryCharge + tip - discount;
                            var data = {
                                'orderOTP': otp,
                                'restaurantId': restaurant.id,
                                'restaurantName': restaurant.name,
                                'restaurantImage': restaurant.image,
                                'restaurantCity': restaurant.city,
                                'restaurantStreet': restaurant.street,
                                'restaurantNumber': restaurant.mobile,
                                'restaurantPosition': restaurant.position,
                                'userId': user.uid,
                                'userName': userDetail.name,
                                'userEmail': userDetail.email,
                                'userPhoneNumber': userDetail.mobile,
                                'deliveryAddressPersonName': deliveryAddress.name,
                                'deliveryAddressPersonPhoneNumber': deliveryAddress.mobile,
                                'deliveryAddressStreet': deliveryAddress.street,
                                'deliveryAddressPosition': deliveryAddress.position,
                                'items': items,
                                'itemTotal': itemTotal,
                                'deliveryCharge': deliveryCharge,
                                'discount': discount,
                                'discountVoucherId': voucher?.id,
                                'discountVoucherCode': voucher?.code,
                                'tip': tip,
                                'grandTotal': grandTotal,
                                'deliveryPersonId': null,
                                'deliveryPersonName': null,
                                'deliveryPersonPhoneNumber': null,
                                'deliveryPersonImage': null,
                                'deliveryPersonPosition': null,
                                'progress': false,
                                'status': 'ordered',
                                'orderedDate': time,
                                'acceptedDate': null,
                                'confirmedDate': null,
                                'pickedDate': null,
                                'deliveredDate': null,
                                'cancelledDate': null,
                                'cancellationReason': null,
                                'updatedDate': time,
                                'commission': 0,
                                'restaurantBalance': 0,
                                'deliveryPersonBalance': 0,
                                'date': getFormattedDate(),
                                'platform': 'web',
                            };
                            console.log(data);
                        } else {
                            message.warning('Selected delivery address is more than 5 km. Please select another delivery address or order from nearby reaturant!');
                        }
                    } else {
                        message.error('Please select delivery address to place order!');
                    }
                } else {
                    message.error('Please login to place order!');
                }
            } else {
                message.warning('Please add items to cart to continue!');
            }
        } else {
            message.warning('Please select reataurant!');
        }
    }

    return <>
        {contextHolder}
        {path.includes('cart') ?
            <Button type='primary' block style={{ margin: '16px' }} disabled={items.length <= 0 || restaurant === null || deliveryAddress === null} onClick={placeOrder}>Place Orders</Button> :
            <FooterMenu />
        }
    </>
}

export default AppFooter;