import { useSelector } from 'react-redux';
import SerchInput from '../inputs/search-input';
import MoreMenus from './more-menus';
import { locationAddressReducer, locationCityReducer } from '../../redux/reducers';
import { Button } from 'antd';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { useState } from 'react';
import Logo from '../../assets/logo.png';
import SelectCity from '../inputs/select-city';

const HeaderMenu = () => {
    const city = useSelector(locationCityReducer);
    const address = useSelector(locationAddressReducer);
    const [visible, setVisible] = useState(false);
    return <div className="header__menu">
        <div style={{ width: '48px', height: '48px' }}>
            <img src={Logo} alt='Toeato Logo' />
        </div>
        <div style={{ flex: 1, lineHeight: 1, display: 'flex', gap: '12px' }}>
            {
                visible ?
                    <SerchInput /> :
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '4px' }}>
                        {!address && <SelectCity />}
                        {address && <p style={{ fontWeight: '600', textTransform: 'capitalize' }}>{city ? city : '--:--'}</p>}
                        {address && <p className='ellipsis' style={{ fontSize: '11px', WebkitLineClamp: 1, }}>{address ? address : '--:--'}</p>}
                    </div>
            }

            {city && <Button shape="circle" icon={visible ? <CloseOutlined /> : <SearchOutlined />} onClick={() => setVisible(!visible)} />}
        </div>
        <MoreMenus />
    </div>
}

export default HeaderMenu;
