import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux"
import { cityList } from "../../utils/constants";
import { updateCity } from "../../redux/global-slice";
import { locationCityReducer } from "../../redux/reducers";
const { Option } = Select;

const SelectCity = () => {
    const dispatch = useDispatch();
    const city = useSelector(locationCityReducer);
    return <Select placeholder="Select City" onChange={e => dispatch(updateCity(e))} defaultValue={city} value={city}>
        {cityList.map(city =>
            <Option key={city} value={city}>{city.toUpperCase()}</Option>
        )}
    </Select>
}

export default SelectCity;