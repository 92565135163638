import { Button, Card, Rate } from "antd";
import Meta from "antd/es/card/Meta";
import { useNavigate } from "react-router-dom";
import { RESTAURANT_ROUTE } from "../../utils/route-constants";
import { HeartFilled, HeartOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { currentAuthUserReducer } from "../../redux/reducers";
import { useState } from "react";
// import { addRemoveFavouriteRestaurant } from "../../services/restaurant-service";

const RestaurantCard = ({ restaurant }) => {
    const [item, setItem] = useState(restaurant);
    const navigate = useNavigate();
    const user = useSelector(currentAuthUserReducer);
    return <Card
        hoverable
        cover={<img alt={item.name} src={item.image} />}
        className='restaurant__card'
        onClick={(e) => {
            e.stopPropagation();
            navigate(`/${RESTAURANT_ROUTE}/${item.id}`);
        }}
        actions={[
            <div style={{ lineHeight: 1.25 }}>
                <p>Min Order</p>
                <p>रु{item.minimumOrder}</p>
            </div>,
            <div style={{ lineHeight: 1.25 }}>
                <p>Delivery Time</p>
                <p>{item.deliveryTime} min</p>
            </div>,
            <div style={{ lineHeight: 1.25 }}>
                <p sty>Rating</p>
                <Rate disabled defaultValue={item['averageRating']} />
            </div>,
        ]}
    >
        {item['type'] === 'veg' && <p className='restaurant__type'>PURE VEG</p>}
        {user && <Button
            type='default' danger shape="circle"
            icon={item['likes'].includes(user.uid) ? <HeartFilled /> : <HeartOutlined />}
        // onClick={(e) => {
        //     e.stopPropagation();
        //     const add = item['likes'].includes(user.uid)
        //     console.log(add);
        //     addRemoveFavouriteRestaurant(add, item.id, user.uid).then(() => {
        //         var likes = item['likes'];
        //         if (add) {
        //             likes.push(user.uid);
        //         } else {
        //             const i = likes.indexOf(user.uid);
        //             if (i !== -1) {
        //                 likes.splice(i, 1);
        //             }
        //         }
        //         var updated = {
        //             ...item,
        //             likes: likes,
        //         }
        //         setItem(updated);
        //     })
        // }}
        />}
        <Meta title={item.name} description={item.categories.toString().replace(',', ', ')} />
    </Card>;
}

export default RestaurantCard;