import { Input, Form } from "antd";
import { textareaRules } from "../../utils/input-rules";
const { TextArea } = Input;

const TextareaInput = ({ rows = 5 }) => {
    return <Form.Item name='message' rules={textareaRules}>
        <TextArea rows={rows} placeholder='Write your query...' />
    </Form.Item>
}

export default TextareaInput;