import { Button, Card, Form, message } from "antd"
import NameInput from "./inputs/name-input"
import MobileInput from "./inputs/mobile-input"
import TextareaInput from "./inputs/textarea-input"
import { useRef, useState } from "react";
import { submitQueryService } from "../services/query-service";
import { useSelector } from "react-redux";
import { currentAuthUserReducer } from "../redux/reducers";

const QueryForm = () => {
    const formRef = useRef(null);
    const user = useSelector(currentAuthUserReducer);
    const [loading, setLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const onFinish = (val) => {
        setLoading(true);
        submitQueryService(user.uid, val['name'], val['mobile'], val['message'])
            .then(() => {
                messageApi.success('Query submitted successfully!');
                formRef.current.resetFields();
            })
            .catch(() => messageApi.error('Something went wrong!'))
            .finally(() => setLoading(false));
    };
    return <>
        {contextHolder}
        <Card title='Submit Your Query'>
            <Form ref={formRef} initialValues={{ remember: true }} onFinish={onFinish}>
                <NameInput />
                <MobileInput />
                <TextareaInput />
                <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={loading}> Submit Query </Button>
            </Form>
        </Card>
    </>
}

export default QueryForm;