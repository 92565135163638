import { Card, Tag } from "antd";
import Meta from "antd/es/card/Meta";
import { useSelector } from "react-redux";
import { addressesListReducer, currentAuthUserReducer } from "../redux/reducers";
import AuthDialog from "../components/auth/auth-dialog";
import AddAddressDialog from '../components/address/add-address-dialog';

const ManageAddress = () => {
    const user = useSelector(currentAuthUserReducer);
    const lists = useSelector(addressesListReducer);
    return <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        {user ? <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
            <AddAddressDialog />
            {
                lists.map(e => <Card key={e.id} size='small'>
                    <Meta title={`${e['name']}, ${e['mobile']}`} description={e['street']} />
                    {e['addressType'] && <Tag
                        color='blue'
                        bordered={false}
                        style={{ position: 'absolute', top: '8px', right: '0px', textTransform: 'uppercase', fontSize: '9px', fontWeight: '600' }}
                    >{e['addressType']}</Tag>}
                </Card>)
            }
        </div> : <AuthDialog />}
    </div>
}

export default ManageAddress;