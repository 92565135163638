import {
    ContactsFilled,
    HeartOutlined,
    HomeOutlined,
    ShoppingOutlined,
    UserOutlined
} from "@ant-design/icons";
import {
    CONTACTUS_ROUTE,
    FAVOURITES_ROUTE,
    ORDERS_ROUTE,
    PROFILE_ROUTE,
    ROOT_ROUTE,
} from './route-constants';

export const bottomMenus = [
    { label: 'Home', icon: <HomeOutlined />, to: ROOT_ROUTE },
    { label: 'Orders', icon: <ShoppingOutlined />, to: ORDERS_ROUTE },
    { label: 'Favourites', icon: <HeartOutlined />, to: FAVOURITES_ROUTE },
    { label: 'Profile', icon: <UserOutlined />, to: PROFILE_ROUTE },
    { label: 'Contact Us', icon: <ContactsFilled />, to: CONTACTUS_ROUTE, },
];

export const cityList = ['birgunj', 'kalaiya'];