import { Card } from "antd"
import Meta from "antd/es/card/Meta"
import { useSelector } from "react-redux"
import {
    cartDeliveryChargeReducer,
    cartDiscountReducer,
    cartItemsReducer,
    cartItemsTotalReducer,
    cartTipReducer
} from "../../redux/reducers";

const BillSummary = () => {
    const itemTotal = useSelector(cartItemsTotalReducer);
    const deliveryCharge = useSelector(cartDeliveryChargeReducer);
    const tip = useSelector(cartTipReducer);
    const discount = useSelector(cartDiscountReducer);
    const grandTotal = itemTotal + deliveryCharge + tip - discount;
    return <Card size='small' style={{}}>
        <Meta
            title='Bill Summary'
            description='Bill details with sub total, discount, tip and delivery chanrges.'
        />
        <div className="bill__summary__info">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Item Total</span>
                <span>रु{itemTotal}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Delivery Charge</span>
                <span>रु{deliveryCharge}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>TIP</span>
                <span>रु{tip}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Discount</span>
                <span>रु{discount}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Grand Total</span>
                <span>रु{grandTotal}</span>
            </div>
        </div>
    </Card>
}

export default BillSummary;