import { createSlice } from '@reduxjs/toolkit'
import { getFormattedCity } from '../utils/utils';

export const globalSlice = createSlice({
    name: 'global',
    initialState: {
        loading: true,
        latitude: null,
        longitude: null,
        address: null,
        city: null,
        banners: [],
        categories: [],
        restaurants: [],
        search: '',
    },
    reducers: {
        setLocationLoading: (state, action) => {
            state.loading = action.payload;
        },
        updateLocationPosition: (state, action) => {
            state.latitude = action.payload.latitude;
            state.longitude = action.payload.longitude;
        },
        updateCity: (state, action) => {
            state.city = action.payload;
        },
        updateLocationAddressReducer: (state, action) => {
            var map = action.payload;
            var city = null;
            if ('city' in map['address']) {
                city = map['address']['city'];
            } else if ('town' in map['address']) {
                city = map['address']['town']
            } else if ('municipality' in map['address']) {
                city = map['address']['municipality'];
            } else {
                city = map['address']['state_district'];
            }
            state.address = map['display_name'];
            state.city = getFormattedCity(city);
        },
        setBannersListReducer: (state, action) => {
            state.banners = action.payload;
        },
        setCategoriesListReducer: (state, action) => {
            state.categories = action.payload;
        },
        setRestaurantsList: (state, action) => {
            state.restaurants = action.payload;
        },
        updateSearch: (state, action) => {
            state.search = action.payload;
        },
    },
});

export const {
    setLocationLoading,
    updateLocationPosition,
    updateCity,
    updateLocationAddressReducer,
    setBannersListReducer,
    setCategoriesListReducer,
    setRestaurantsList,
    updateSearch,
} = globalSlice.actions

export default globalSlice.reducer;

/*{
  "place_id": 249103823,
  "licence": "Data © OpenStreetMap contributors, ODbL 1.0. http://osm.org/copyright",
  "osm_type": "relation",
  "osm_id": 15699558,
  "lat": "13.005320000000001",
  "lon": "77.59597375163747",
  "category": "amenity",
  "type": "grave_yard",
  "place_rank": 30,
  "importance": 0.00000999999999995449,
  "addresstype": "amenity",
  "name": "Khud-us-Sahib Muslim Graveyard",
  "display_name": "Khud-us-Sahib Muslim Graveyard, Nandi Durga Road, JC Nagar, Ramaswamy Palya Ward, East Zone, Bengaluru, Bangalore North, Bengaluru Urban District, Karnataka, 560006, India",
  "address": {
    "amenity": "Khud-us-Sahib Muslim Graveyard",
    "road": "Nandi Durga Road",
    "suburb": "JC Nagar",
    "city_district": "East Zone",
    "city": "Bengaluru",
    "county": "Bangalore North",
    "state_district": "Bengaluru Urban District",
    "state": "Karnataka",
    "ISO3166-2-lvl4": "IN-KA",
    "postcode": "560006",
    "country": "India",
    "country_code": "in"
  },
  "boundingbox": [
    "13.0018907",
    "13.0066078",
    "77.5937740",
    "77.5979798"
  ]
}*/