import { Input, Form } from "antd";
import { textareaRules } from "../../utils/input-rules";
import { BarsOutlined } from "@ant-design/icons";

const AddressTypeInput = () => {
    return <Form.Item name='addressType' rules={textareaRules}>
        <Input prefix={<BarsOutlined />} placeholder='Address Type(e.g home, office, etc)' />
    </Form.Item>
}

export default AddressTypeInput;