import { useState } from "react";
import { currentAuthUserReducer, locationAddressReducer, locationLatitudeReducer, locationLongitudeReducer } from "../../redux/reducers";
import { Button, Form, Modal, message } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import NameInput from "../inputs/name-input";
import MobileInput from "../inputs/mobile-input";
import AddressTypeInput from "../inputs/address-type-input";
import AddressInput from "../inputs/address-input";
import { useSelector } from "react-redux";
import { addAddressService } from "../../services/auth-service";

const AddAddressDialog = () => {
    const user = useSelector(currentAuthUserReducer);
    const lat = useSelector(locationLatitudeReducer);
    const lng = useSelector(locationLongitudeReducer);
    const address = useSelector(locationAddressReducer);
    const [dialog, setDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();

    const onFinish = (values) => {
        console.log(values);
        if (lat !== null && lng !== null && address !== null) {
            setLoading(true);
            addAddressService(user.uid, values['addressType'], values['name'], values['mobile'], address, lat, lng)
                .then(() => messageApi.success('Address added successfully!'))
                .catch(e => messageApi.error('Something went wrong while adding location!'))
                .finally(() => setLoading(false));
        } else {
            navigator.permissions.query({ name: 'geolocation' }).then(permissionStatus => {
                if (permissionStatus.state === 'denied') {
                    alert('Please allow location access.');
                    window.location.href = "app-settings:location";
                    // window.open('app-settings:location', '_blank')
                } else {
                    navigator.geolocation.getCurrentPosition(pos => console.log(pos), e => console.log(e));
                }
            }).catch(e => console.log(e));
            messageApi.warning('Please allow location to continue...');
        }
    };

    return <>
        {contextHolder}
        <Button type='primary' icon={<PlusOutlined />} onClick={() => {
            if (lat !== null && lng !== null && address !== null) {
                setDialog(true);
            } else {
                messageApi.warning('Please allow location to continue...');
            }
        }}>Add Address</Button>
        <Modal
            title='Add Address'
            footer={null}
            centered open={dialog}
            confirmLoading={loading}
            destroyOnClose
        >
            <Form initialValues={{ remember: true, ['address']: address }} onFinish={onFinish}>
                <AddressTypeInput />
                <NameInput />
                <MobileInput />
                <AddressInput />
                <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: '100%' }}
                    loading={loading}
                    icon={<PlusOutlined />}
                > Add Address </Button>
            </Form>
            <Button
                size='small'
                danger
                icon={<CloseOutlined />}
                onClick={() => setDialog(false)}
                style={{ position: 'absolute', top: '20px', right: '24px' }}
            />
        </Modal>
    </>
}

export default AddAddressDialog;