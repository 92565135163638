import { Form, Input } from "antd";
import { nameRules } from "../../utils/input-rules";
import { UserOutlined } from "@ant-design/icons";

const NameInput = ({ disabled }) => {
    return <Form.Item name="name" rules={nameRules}>
        <Input
            prefix={<UserOutlined />}
            placeholder="Full Name"
            disabled={disabled}
        />
    </Form.Item>
}

export default NameInput;