import { collection, doc, getDoc, getDocs, query, where } from '@firebase/firestore';
import { db } from '../utils/firebase-config';

// get city settings
export async function getCitySettingsService(city) {
    try {
        const docRef = doc(db, 'settings', city.toLocaleUpperCase());
        var snapDoc = await getDoc(docRef);
        if (snapDoc.exists()) {
            console.log(snapDoc.data());
            return snapDoc.data();
        } else {
            return null;
        }
    } catch (error) {
        console.error(error);
    }
}

// get all the banners by city
export async function getbannersListReducerService(city = 'birgunj') {
    try {
        const q = query(
            collection(db, 'banners'),
            where('city', '==', city),
            where('status', '==', true)
        );
        const querySnapshot = await getDocs(q);
        var data = [];
        querySnapshot.forEach((doc) => {
            data.push({ ...doc.data(), id: doc.id });
        });
        return data;
    } catch (error) {
        console.error(error);
    }
}

// get list of all categories
export async function getcategoriesListReducerService(city = 'birgunj') {
    try {
        const q = query(
            collection(db, 'categories'),
            where('cities', 'array-contains', city),
            where('status', '==', true)
        );
        const querySnapshot = await getDocs(q);
        var data = [];
        querySnapshot.forEach((doc) => {
            data.push({ ...doc.data(), id: doc.id });
        });
        return data;
    } catch (error) {
        console.error(error);
    }
}

// get list of all restaurant
export async function getRestaurantListService(city = 'birgunj') {
    try {
        const q = query(
            collection(db, 'restaurants'),
            where('city', '==', city),
            where('status', '==', true)
        );
        const querySnapshot = await getDocs(q);
        var data = [];
        querySnapshot.forEach((doc) => {
            data.push({ ...doc.data(), id: doc.id, likes: Array.from(doc.data()['likes']) });
        });
        return data;
    } catch (error) {
        console.error(error);
    }
}