import { MailOutlined } from "@ant-design/icons";
import { Form, Input } from "antd";
import { emailRules } from "../../utils/input-rules";

const EmailInput = ({ disabled }) => {
    return <Form.Item name="email" rules={emailRules}>
        <Input
            prefix={<MailOutlined />}
            placeholder="Email"
            disabled={disabled}
        />
    </Form.Item>
}

export default EmailInput;