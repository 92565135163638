import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        userDetail: null,
        orders: [],
        favourites: [],
        addresses: [],
    },
    reducers: {
        setAuthUser: (state, action) => {
            state.user = action.payload;
        },
        setAuthUserDetail: (state, action) => {
            state.userDetail = action.payload;
        },
        setUserOrders: (state, action) => {
            state.orders = action.payload;
        },
        setUserFavourite: (state, action) => {
            state.favourites = action.payload;
        },
        setUserAddresses: (state, action) => {
            state.addresses = action.payload;
        },
        logoutUser: (state) => {
            state.user = null;
            state.userDetail = null;
            state.orders = [];
            state.favourites = [];
            state.addresses = [];
        }
    },
});

export const {
    setAuthUser,
    setAuthUserDetail,
    setUserOrders,
    setUserFavourite,
    setUserAddresses,
    logoutUser
} = authSlice.actions

export default authSlice.reducer