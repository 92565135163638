import { Button, Card } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../redux/auth-slice";
import { logoutService } from "../../services/auth-service";
import { LogoutOutlined } from "@ant-design/icons";
import { currentAuthUserDetailReducer } from "../../redux/reducers";

const ProfileInfo = ({ messageApi }) => {
    const dispatch = useDispatch();
    const detail = useSelector(currentAuthUserDetailReducer);
    // logout user
    const logout = () => {
        logoutService().then(() => {
            dispatch(logoutUser());
            messageApi.success('Logged out successfully!');
        }).catch(e => {
            messageApi.error(e.message);
        })
    }
    return <Card loading={detail === null}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {detail && <div style={{ flex: 1 }}>
                <p>{detail['name']}, {detail['mobile']}</p>
                <p>{detail['email']}</p>
            </div>}
            <Button type='primary' shape='circle' danger icon={<LogoutOutlined />} onClick={logout} />
        </div>
    </Card>
}

export default ProfileInfo;