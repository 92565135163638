import React from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { NavLink } from 'react-router-dom';
import { MANAGE_ADDRESS_ROUTE, QUERIES_ROUTE } from '../../utils/route-constants';

function _getItem(key, label) {
    return { key: key, label: label }
}

const items = [
    _getItem('1', <NavLink to={MANAGE_ADDRESS_ROUTE}>Manage Address</NavLink>),
    // _getItem('2', <NavLink to={MESSAGES_ROUTE}>My Messages</NavLink>),
    _getItem('3', <NavLink to={QUERIES_ROUTE}>My Queries</NavLink>),
];
const MoreMenus = () => (
    <Dropdown menu={{ items }}>
        <MenuOutlined />
    </Dropdown>
);
export default MoreMenus;