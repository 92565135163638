import { Button, Form } from "antd";
import NameInput from "../inputs/name-input";
import GenderInput from "../inputs/gender-input";
import EmailInput from "../inputs/email-input";
import MobileInput from "../inputs/mobile-input";
import PasswordInput from "../inputs/password-input";
import { registerService } from "../../services/auth-service";

const RegisterTab = ({ loading, setLoading, setDialog, messageApi }) => {
    const onFinish = (values) => {
        setLoading(true);
        registerService(
            values['name'],
            values['email'],
            values['gender'],
            values['mobile'],
            values['password'],
        ).then(_ => {
            messageApi.success('Account created successfully!');
            setDialog(false);
        }).catch((e) => {
            messageApi.error(e.message);
        }).finally(() => setLoading(false))
    };
    return <Form initialValues={{ remember: true }} onFinish={onFinish}>
        <NameInput />
        <GenderInput />
        <EmailInput />
        <MobileInput />
        <PasswordInput />
        <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={loading}>Resgiter</Button>
    </Form>
}

export default RegisterTab;