import ApplyVoucher from './apply-voucher';
import ApplyTip from './apply-tip';
import BillSummary from './bill-summary';
import CancellationPolicy from './cancellation-policy';
const CartInfo = () => {
    return <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
        {/* <ApplyVoucher /> */}
        <ApplyTip />
        <BillSummary />
        <CancellationPolicy />
    </div>
}

export default CartInfo;