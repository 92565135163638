import { Button } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, removeFromCart } from '../../redux/cart-slice';
import { cartItemsReducer } from '../../redux/reducers';

const ProductList = ({ isOpen, restaurant, items }) => {
    const dispatch = useDispatch();
    const cartItems = useSelector(cartItemsReducer);
    return <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
        {items.map(item => {
            var existInCart = cartItems.find(e => e.id === item.id);
            return <div
                key={item.id}
                className='product__card'
                style={{ opacity: isOpen ? 1 : .6 }}
            >
                <img src={item.image} alt={item.name} />
                <div style={{ flex: '1' }}>
                    <div style={{ textTransform: 'capitalize' }}>{item.name}</div>
                    <div>रु{item.sellingPrice}</div>
                </div>
                <Button
                    disabled={!isOpen}
                    type='primary'
                    danger={existInCart}
                    icon={existInCart ? <MinusOutlined /> : <PlusOutlined />}
                    onClick={() => existInCart ? dispatch(removeFromCart(item.id)) : dispatch(addToCart({ restaurant: restaurant, product: item }))}
                >
                    {existInCart ? 'REMOVE' : 'ADD'}
                </Button>


            </div>
        })}
    </div >
}

export default ProductList;