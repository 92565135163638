import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth-slice';
import globalSlice from './global-slice';
import cartReducer from './cart-slice';
import { setupListeners } from '@reduxjs/toolkit/query';
const store = configureStore({
    reducer: {
        auth: authReducer,
        global: globalSlice,
        cart: cartReducer,
    },
    middleware: (middleware) => middleware({ serializableCheck: false })
});

export default store;

setupListeners(store.dispatch);