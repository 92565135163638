import { Button, Select, Tag } from "antd";
import { addressesListReducer, cartDeliveryAddressReducer } from "../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { updateDeliveryAddress } from "../../redux/cart-slice";
import { CloseOutlined } from "@ant-design/icons";
const { Option } = Select;

const SelectDeliveryAddress = () => {
    const dispatch = useDispatch();
    const lists = useSelector(addressesListReducer);
    const address = useSelector(cartDeliveryAddressReducer);
    const defaultValue = address !== null ? address.id : null;
    return <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '12px' }}>
        <Select
            placeholder="Select Delivery Address"
            removeIcon
            onChange={e => {
                const obj = lists.find(x => x.id === e);
                if (obj) dispatch(updateDeliveryAddress(obj));
                else dispatch(updateDeliveryAddress(null));
            }}
            value={defaultValue}
            style={{ width: 'calc(100% - 52px)', height: '40px' }}
        >
            {lists.map(list =>
                <Option key={list.id} value={list.id} style={{ lineHeght: '1' }}>
                    <div style={{ fontSize: '12px', fontWeight: '600', lineHeight: '1.3' }}>{list.name}, {list.mobile}</div>
                    <div style={{ fontSize: '10px', fontWeight: 'normal', lineHeight: '1.3' }}>{list.street}</div>
                    {list['addressType'] && <Tag
                        color='blue'
                        bordered={false}
                        style={{ position: 'absolute', top: '2px', right: '0', textTransform: 'uppercase', fontSize: '8px', fontWeight: '600', lineHeight: '14px', marginInlineEnd: 0 }}
                    >{list['addressType']}</Tag>}
                </Option>
            )}
        </Select>
        <Button style={{ height: '40px', width: '40px' }} icon={<CloseOutlined />} onClick={() => dispatch(updateDeliveryAddress(null))} />
    </div>
}

export default SelectDeliveryAddress;