import { Input, Form } from "antd";
import { textareaRules } from "../../utils/input-rules";
import { EnvironmentOutlined } from "@ant-design/icons";

const AddressInput = ({ disabled = true }) => {
    return <Form.Item name='address' rules={textareaRules}>
        <Input prefix={<EnvironmentOutlined />} placeholder='Address' disabled={disabled} />
    </Form.Item>
}

export default AddressInput;