import HomeCarousel from '../components/home/carousel';
import CategoriesList from '../components/home/categories-list';
import HomeRestaurantList from '../components/home/home-restaurant-list';

const Home = () => {

    return <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <HomeCarousel />
        <CategoriesList />
        <HomeRestaurantList />
    </div>
}

export default Home;