import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom";
import { CATEGORY_ROUTE } from '../../utils/route-constants';
import { categoriesListReducer } from "../../redux/reducers";

const CategoriesList = () => {
    const navigate = useNavigate();
    const categories = useSelector(categoriesListReducer);
    return <div className='categories'>
        {
            categories.length > 0 ? categories.map(list => <div key={list.id} className='category__card' onClick={() => navigate(`/${CATEGORY_ROUTE}/${list.name}`)} >
                <img src={list.image} alt={list.name} />
                <span>{list.name}</span>
            </div>) : <div>No categories found</div>
        }
    </div>
}

export default CategoriesList;