import { useSelector } from "react-redux";
import { currentAuthUserReducer, favouritesListReducer } from "../redux/reducers";
import { Empty } from "antd";
import AuthDialog from "../components/auth/auth-dialog";
import RestaurantCard from "../components/restaurant/restaurant-card";

const Favourites = () => {
    const user = useSelector(currentAuthUserReducer);
    const lists = useSelector(favouritesListReducer);

    return user != null ? <div className='favourites'>
        {
            lists.length > 0 ?
                <div className='home__restaurant__list'>
                    {
                        lists.map(list => <RestaurantCard key={list['id']} restaurant={list} />)
                    }
                </div> :
                <Empty />
        }
    </div> : <div style={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
        <AuthDialog />
    </div>
}

export default Favourites;