import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import './index.scss';
import store from './redux/store';
import App from './pages/app';
import Home from './pages/home';
import {
  CART_ROUTE,
  CATEGORY_ROUTE,
  CONTACTUS_ROUTE,
  FAVOURITES_ROUTE,
  MANAGE_ADDRESS_ROUTE,
  MESSAGES_ROUTE,
  ORDERS_ROUTE,
  PROFILE_ROUTE,
  QUERIES_ROUTE,
  RESTAURANT_ROUTE,
  ROOT_ROUTE,
  SEARCH_ROUTE
} from './utils/route-constants';
import Cart from './pages/cart';
import RestaurantByCategory from './pages/restaurant-by-category';
import RestaurantDetail from './pages/restaurant-detail';
import Orders from './pages/orders';
import Favourites from './pages/favourites';
import Profile from './pages/profile';
import Search from './pages/search';
import ManageAddress from './pages/manage-address';
import Messages from './pages/messages';
import Queries from './pages/queries';
import ContactUs from './pages/contact-us';
import { getIndividualRestaurantService } from './services/restaurant-service';

const router = createBrowserRouter([
  {
    path: ROOT_ROUTE,
    element: <App />,
    children: [
      { index: true, path: ROOT_ROUTE, element: <Home /> },
      { path: SEARCH_ROUTE, element: <Search /> },
      { path: ORDERS_ROUTE, element: <Orders /> },
      { path: FAVOURITES_ROUTE, element: <Favourites /> },
      { path: PROFILE_ROUTE, element: <Profile /> },
      { path: CONTACTUS_ROUTE, element: <ContactUs /> },
      { path: CART_ROUTE, element: <Cart /> },
      { path: `${CATEGORY_ROUTE}/:category`, element: <RestaurantByCategory /> },
      {
        path: `${RESTAURANT_ROUTE}/:id`,
        element: <RestaurantDetail />,
        errorElement: <div>Something went wrong!</div>,
        loader: (params) => {
          const id = params.params['id'];
          if (id) return getIndividualRestaurantService(id);
          return null;
        }
      },
      { path: MANAGE_ADDRESS_ROUTE, element: <ManageAddress /> },
      { path: MESSAGES_ROUTE, element: <Messages /> },
      { path: QUERIES_ROUTE, element: <Queries /> },
    ],
  },

]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
