import { Card } from "antd";
import { Outlet } from "react-router-dom";
import SelectCity from '../inputs/select-city';
import { cityList } from "../../utils/constants";

const AppContent = ({ city }) => {
    if (city !== null && cityList.includes(city)) {
        return <Outlet />
    }
    return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'calc(100vh - 160px)' }}>
        <Card>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <div style={{ fontStyle: 'italic', color: 'red' }}>
                    "Bummer! Looks like Toeato hasn't sprouted in your city yet. Don't worry, we're growing fast, and delicious deliveries will be coming your way soon!"
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    <div>Explore other city</div>
                    <SelectCity />
                </div>
            </div>
        </Card>
    </div>


}

export default AppContent;