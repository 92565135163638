import { arrayRemove, arrayUnion, collection, doc, getDoc, getDocs, query, where } from "@firebase/firestore";
import { db } from "../utils/firebase-config";
import { update } from "firebase/database";

// get individual resturant
export async function getIndividualRestaurantService(id) {
    try {
        const docRef = doc(db, 'restaurants', id);
        const q = query(
            collection(db, 'products'),
            where('restaurantId', '==', id),
            where('status', '==', true)
        );
        var res = await getDoc(docRef);
        if (res.exists()) {
            var querySnapshot = await getDocs(q)
            var items = [];
            querySnapshot.forEach((doc) => {
                items.push({ ...doc.data(), id: doc.id });
            });
            var finalData = {
                restaurant: {
                    ...res.data(),
                    id: res.id,
                },
                products: items,
            }
            console.log(finalData);
            return finalData;
        }
        return null;

    } catch (error) {
        console.error(error);
    }
}

// add / remove restaurant from favourite
export async function addRemoveFavouriteRestaurant(add, restaurantId, userId) {
    try {
        console.log(restaurantId)
        const docRef = doc(collection(db, 'restaurants'), restaurantId);
        const updateData = {
            likes: !add ?
                arrayUnion([userId]) :
                arrayRemove([userId]),
        };
        return await update(docRef, updateData);
    } catch (error) {
        console.error(error);
    }
}

// place order 
