import { Carousel } from "antd";
import { useSelector } from "react-redux";
import { bannersListReducer } from "../../redux/reducers";
import { useNavigate } from "react-router-dom";
import { RESTAURANT_ROUTE } from "../../utils/route-constants";

const HomeCarousel = () => {
    const navigate = useNavigate();
    const banners = useSelector(bannersListReducer);

    return <Carousel autoplay autoplaySpeed={5000}>
        {
            banners.length > 0 ? banners.map(list => <div key={list.id} onClick={() => {
                if (list['restaurantId'] != null) {
                    navigate(`/${RESTAURANT_ROUTE}/${list['restaurantId']}`);
                }
            }}>
                <img src={list['imageUrl']} style={{ aspectRatio: '16 / 9', borderRadius: '6px' }} />
            </div>) : <div>no banners</div>
        }
    </Carousel>
}

export default HomeCarousel;