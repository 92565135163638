import { CloseOutlined, EditFilled } from "@ant-design/icons";
import { Button, Card, Form } from "antd";
import { useState } from "react";
import PasswordInput from "../inputs/password-input";
import PasswordConfirmInput from "../inputs/password-confirm-input";

const UpdatePassword = () => {
    const [loading, setLoading] = useState(false);
    const [update, setUpdate] = useState(false);
    // update user
    const onFinish = (values) => {
        console.log(values)
    };
    return <Card
        title='Update Password'
        extra={<Button
            type='primary'
            shape='circle'
            size='small'
            danger={update}
            icon={update ? <CloseOutlined /> : <EditFilled />}
            onClick={() => setUpdate(!update)}
        />}
    >
        <Form initialValues={{ remember: true }} onFinish={onFinish}>
            <PasswordInput disabled={!update} />
            <PasswordConfirmInput disabled={!update} />
            <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={loading} disabled={!update}>Update Password</Button>
        </Form>
    </Card>
}

export default UpdatePassword;