import { getFormattedTime } from "../../utils/utils";

const RestaurantInfo = ({ restaurant, isOpen }) => {
    return <div>
        <div style={{ position: 'relative', aspectRatio: '16 / 9', borderRadius: '8px', overflow: 'hidden' }}>
            <img src={restaurant.image} alt={restaurant.name} />
            {!isOpen && <div style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                background: 'rgba(0, 0, 0, .4)',
                display: 'flex',
                gap: '6px',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '20px',
                fontWeight: '600',
                color: '#f5f5f5',
                textAlign: 'center',
            }}>
                <div>Sorry we are</div>
                <div>CLOSED</div>
                <div>Opens {getFormattedTime(restaurant['openingTime'])}</div>
            </div>}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>{getFormattedTime(restaurant.openingTime)}</div>
            <div>{getFormattedTime(restaurant.closingTime)}</div>
        </div>
        <div style={{ textTransform: 'capitalize' }}>{restaurant.categories.toString()}</div>
        <div>{restaurant.description}</div>
    </div>
}

export default RestaurantInfo;