import { useEffect } from 'react';
import { Layout } from 'antd';
import { useLocation } from 'react-router-dom';
import { auth, db } from '../utils/firebase-config';
import { onAuthStateChanged } from '@firebase/auth';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAuthUser,
  setAuthUserDetail,
  setUserAddresses,
  setUserFavourite,
  setUserOrders
} from '../redux/auth-slice';
import { getLocationDetail } from '../services/location-service';
import { globalLoadingReducer, locationCityReducer } from '../redux/reducers';
import {
  getCitySettingsService,
  getRestaurantListService,
  getbannersListReducerService,
  getcategoriesListReducerService
} from '../services/list-service';
import {
  setBannersListReducer,
  setCategoriesListReducer,
  setLocationLoading,
  setRestaurantsList,
  updateLocationAddressReducer,
  updateLocationPosition,
} from '../redux/global-slice';
import CartButton from '../components/app/cart-button';
import Loader from '../components/loader';
import AppHeader from '../components/app/app-header';
import AppFooter from '../components/app/app-footer';
import { collection, doc, onSnapshot, orderBy, query, where } from '@firebase/firestore';
import { getFormattedOrderDate } from '../utils/utils';
import { setCartSetting } from '../redux/cart-slice';
import AppContent from '../components/app/app-content';
const { Header, Content, Footer } = Layout;

const App = () => {
  const dispatch = useDispatch();
  const city = useSelector(locationCityReducer);
  const location = useLocation();
  const pathname = location.pathname;
  const loading = useSelector(globalLoadingReducer);

  // request & get current loction
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        dispatch(setLocationLoading(true));
        dispatch(updateLocationPosition(position.coords));
        getLocationDetail(position.coords.latitude, position.coords.longitude)
          .then(res => dispatch(updateLocationAddressReducer(res)))
          .finally(() => dispatch(setLocationLoading(false)));
      }, e => {
        console.log(e);
        dispatch(setLocationLoading(false));
      });
    }
  }, []);

  const _getList = async () => {
    dispatch(setLocationLoading(true));
    await getCitySettingsService(city.toLocaleUpperCase()).then(res => dispatch(setCartSetting(res)));
    await getbannersListReducerService(city).then(res => dispatch(setBannersListReducer(res)));
    await getcategoriesListReducerService(city).then(res => dispatch(setCategoriesListReducer(res)));
    await getRestaurantListService(city).then(res => dispatch(setRestaurantsList(res)));
    dispatch(setLocationLoading(false));
  }

  // get & set all the list
  useEffect(() => {
    if (city != null) {
      _getList();
    } else {
      dispatch(setCartSetting(null));
    }
  }, [city]);

  // get & current user
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      dispatch(setAuthUser(user));
      if (user) {
        // get users detail
        onSnapshot(doc(collection(db, 'users'), user.uid), (querySnapshot) => {
          const newData = { ...querySnapshot.data(), id: querySnapshot.id };
          dispatch(setAuthUserDetail(newData));
        }, (error) => {
          console.log(error);
        });
        // get all the address list
        const parentDocRef = doc(db, 'users', user.uid);
        const subCollectionQuery = query(collection(parentDocRef, 'address'));
        onSnapshot(subCollectionQuery, (querySnapshot) => {
          const newData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
          dispatch(setUserAddresses(newData));
        }, (error) => {
          console.log(error);
        });
        // get all orders
        const query1 = query(collection(db, 'orders'), where('userId', '==', user.uid), orderBy('orderedDate', 'desc'));
        onSnapshot(query1, (querySnapshot) => {
          const newData = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
            itemTotal: doc.data()['itemTotal'].toFixed(2),
            deliveryCharge: doc.data()['deliveryCharge'].toFixed(2),
            discount: doc.data()['discount'].toFixed(2),
            grandTotal: doc.data()['grandTotal'].toFixed(2),
            orderedDate: getFormattedOrderDate(doc.data()['orderedDate'])
          }));
          dispatch(setUserOrders(newData));
        }, (error) => {
          console.log(error);
        });
        // get all favourite resturants
        const query2 = query(collection(db, 'restaurants'), where('likes', 'array-contains', user.uid));
        onSnapshot(query2, (querySnapshot) => {
          const newData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
          dispatch(setUserFavourite(newData));
        }, (error) => {
          console.log(error);
        });
      }
    });
    return () => unsubscribe();
  }, []);



  return (
    <Layout>
      <Header>
        <AppHeader path={pathname} />
      </Header>
      <Content>
        <AppContent city={city} />
        {(!pathname.includes('cart') && city !== null) && <CartButton />}
      </Content>
      {(pathname.includes('restaurant') || pathname.includes('category')) ? null : <Footer>
        <AppFooter path={pathname} />
      </Footer>}
      {loading && <Loader />}
    </Layout>
  );
};
export default App;