import { useSelector } from "react-redux";
import { currentAuthUserReducer } from "../redux/reducers";
import AuthDialog from "../components/auth/auth-dialog";
import { message } from "antd";
import ProfileInfo from "../components/profile/profile-info";
import UpdateProfile from "../components/profile/update-profile";
import UpdatePassword from "../components/profile/update-password";

const Profile = () => {
    const user = useSelector(currentAuthUserReducer);
    const [messageApi, contextHolder] = message.useMessage();

    return <>
        {contextHolder}
        <div className='profile'>
            {
                user ?
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                        <ProfileInfo messageApi={messageApi} />
                        <UpdateProfile />
                        <UpdatePassword />
                    </div> :
                    <AuthDialog />
            }
        </div>
    </>
}

export default Profile;