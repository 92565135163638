import { createSlice } from '@reduxjs/toolkit';
import { getDeliveryDistanceService } from '../services/location-service';

export const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        loading: false,
        settings: null,
        items: [],
        restaurant: null,
        voucher: null,
        tip: 0,
        deliveryAddress: null,
        deliveryDistance: 0,
    },
    reducers: {
        setCartSetting: (state, action) => {
            state.settings = action.payload;
        },
        addToCart: (state, action) => {
            const { restaurant, product } = action.payload;
            const lists = [...state.items];
            const toBeAdded = {
                id: product.id,
                name: product.name,
                image: product.image,
                category: product.category,
                commission: product.commission,
                commissionType: product.commissionType,
                price: product.price,
                sellingPrice: product.sellingPrice,
                quantity: 1,
                description: product.description,
            }
            if (restaurant.id === state.restaurant?.id) {
                const itemIndex = lists.findIndex((item) => item.id === product.id)
                const itemInCart = lists.find((item) => item.id === product.id);
                if (itemInCart) {
                    const quantity = itemInCart['quantity'] + 1;
                    const updated = {
                        ...toBeAdded,
                        quantity: quantity,
                    }
                    lists[itemIndex] = updated;
                } else {
                    lists.push(toBeAdded);
                }
            } else {
                lists.push(toBeAdded);
            }
            if (state.deliveryAddress !== null && state.restaurant !== null && state.restaurant.id !== restaurant.id) {
                const origin = state.restaurant.position.geopoint;
                const destination = state.deliveryAddress.position.geopoint;
                state.loading = true;
                getDeliveryDistanceService(`${origin.latitude},${origin.longitude}`, `${destination.latitude}, ${destination.longitude}`)
                    .then(x => state.deliveryDistance = x)
                    .catch(() => state.deliveryDistance = 0)
                    .finally(() => state.loading = false);
            }
            state.restaurant = restaurant;
            state.items = lists;
        },
        removeFromCart: (state, action) => {
            const id = action.payload;
            const lists = state.items;
            const itemIndex = lists.findIndex((item) => item.id === id)
            const itemInCart = lists.find((item) => item.id === id);
            const quantity = itemInCart.quantity - 1;
            if (quantity > 0) {
                const updated = {
                    ...itemInCart,
                    quantity: quantity,
                }
                lists[itemIndex] = updated;
            } else {
                lists.splice(itemIndex, 1);
            }
            state.items = lists;
            if (lists.length <= 0) {
                state.restaurant = null;
            }
        },
        updateVoucher: (state, action) => {
            state.voucher = action.payload;
        },
        setTip: (state, action) => {
            if (state.tip !== action.payload) {
                state.tip = action.payload;
            } else {
                state.tip = 0;
            }
        },
        updateDeliveryAddress: (state, action) => {
            const address = action.payload;
            state.deliveryAddress = address;
            if (address !== null && state.restaurant !== null) {
                const origin = state.restaurant.position.geopoint;
                const destination = address.position.geopoint;
                state.loading = true;
                getDeliveryDistanceService(`${origin.latitude},${origin.longitude}`, `${destination.latitude}, ${destination.longitude}`)
                    .then(x => state.deliveryDistance = x)
                    .catch(() => state.deliveryDistance = 0)
                    .finally(() => state.loading = false);
            } else {
                state.deliveryDistance = 0;
            }
        },
        clearCart: (state) => {
            state.loading = false;
            state.items = [];
            state.restaurant = null;
            state.tip = 0;
            state.voucher = null;
            state.deliveryAddress = null;
            state.deliveryDistance = 0;
        },
    },
});

export const {
    setCartSetting,
    addToCart,
    removeFromCart,
    updateVoucher,
    setTip,
    clearCart,
    updateDeliveryAddress,
} = cartSlice.actions

export default cartSlice.reducer