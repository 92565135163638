import { NavLink } from 'react-router-dom';
import { bottomMenus } from '../../utils/constants'
const FooterMenu = () => {
    return <div className="footer__menu">
        {
            bottomMenus.map((menu, i) => <NavLink key={i} to={menu.to}>
                {menu.icon}
                <span>{menu.label}</span>
            </NavLink>)
        }
    </div>
}

export default FooterMenu;