import { ShoppingCartOutlined } from "@ant-design/icons";
import { FloatButton } from "antd";
import { useNavigate } from "react-router-dom";
import { CART_ROUTE } from "../../utils/route-constants";

const CartButton = () => {
    const navigate = useNavigate();
    return <FloatButton
        icon={<ShoppingCartOutlined />}
        type="primary"
        onClick={() => navigate(`/${CART_ROUTE}`)}
    />
}

export default CartButton;