import { useSelector } from "react-redux";
import { currentAuthUserReducer, ordersListReducer } from "../redux/reducers";
import AuthDialog from "../components/auth/auth-dialog";
import { Avatar, Empty } from "antd";
import { getOrderStatusColor } from "../utils/utils";

const Orders = () => {
    const user = useSelector(currentAuthUserReducer);
    const orders = useSelector(ordersListReducer);

    return user != null ? <div className='orders'>
        {
            orders.length > 0 ?
                <div className='orders__list'>
                    {
                        orders.map(order => <div key={order.id} className='order__card'>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p>{order['orderedDate']}</p>
                                <div className='order__status' style={{ background: getOrderStatusColor(order['status']) }}>{order['status']}</div>
                            </div>
                            <div className='divider' />
                            <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                                <Avatar size='large' src={order['restaurantImage']} />
                                <div>
                                    <p>{order['restaurantName']}</p>
                                    <p>{order['restaurantStreet']}</p>
                                </div>
                            </div>

                            <div className='divider' />
                            <div className='order__items'>
                                {
                                    order['items'].map(item => <div key={item['id']} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <p>{item['name']}</p>
                                        <p>{item['quantity']} x रु{item['sellingPrice']} = रु{(item['quantity'] * item['sellingPrice']).toFixed(2)}</p>
                                    </div>)
                                }
                            </div>
                            <div className='divider' />
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <p>Item Total</p>
                                    <p>रु{order['itemTotal']}</p>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <p>Delivery Charge</p>
                                    <p>रु{order['deliveryCharge']}</p>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <p>Discount</p>
                                    <p>रु{order['discount']}</p>
                                </div>
                                {order['tip'] > 0 && <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <p>Tip</p>
                                    <p>रु{order['tip']}</p>
                                </div>}
                                <div className='divider' style={{ margin: '4px 0' }} />
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <p>Grand Total</p>
                                    <p>रु{order['grandTotal']}</p>
                                </div>
                            </div>
                            <div className='divider' />
                            <div>
                                <p>{order['deliveryAddressPersonName']}, {order['deliveryAddressPersonPhoneNumber']}</p>
                                <p>{order['deliveryAddressStreet']}</p>
                            </div>
                        </div>)
                    }
                </div> :
                <Empty />
        }
    </div> : <div style={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
        <AuthDialog />
    </div>
}

export default Orders;