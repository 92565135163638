import { EnvironmentOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { Card } from "antd";

const ContactusInfo = () => {
    const _info = [
        { icon: <EnvironmentOutlined color='#f5f5f5' />, title: 'Address', value: 'Toeato Pvt. Ltd. Birgunj, Nepal' },
        { icon: <MailOutlined color='#f5f5f5' />, title: 'Email', value: 'toeato0@gmail.com' },
        { icon: <PhoneOutlined color='#f5f5f5' />, title: 'Contact', value: '+977 9802588671, +977 9814245916' },
    ];
    return <Card>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            {
                _info.map((list, i) => <div key={i} style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
                    <div style={{ width: '36px', height: '36px', background: '#f73a59', borderRadius: '6px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{list.icon}</div>
                    <div style={{ lineHeight: '1.3' }}>
                        <div style={{ fontSize: '13px', fontWeight: '600' }}>{list.title}</div>
                        <div style={{ fontSize: '12px' }}>{list.value}</div>
                    </div>
                </div>)
            }
        </div>
    </Card>
}

export default ContactusInfo;