import { Form, InputNumber } from "antd"
import { mobileRules } from "../../utils/input-rules";
import { MobileOutlined } from "@ant-design/icons";

const MobileInput = ({ disabled }) => {
    return <Form.Item name="mobile" rules={mobileRules} >
        <InputNumber
            prefix={<MobileOutlined />}
            placeholder="Mobile Number"
            style={{ width: '100%' }}
            disabled={disabled}
        />
    </Form.Item>
}

export default MobileInput;