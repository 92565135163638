import { Input } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { SEARCH_ROUTE } from '../../utils/route-constants';
import { useDispatch, useSelector } from 'react-redux';
import { searchReducer } from '../../redux/reducers';
import { updateSearch } from '../../redux/global-slice';
import { useEffect } from 'react';
const { Search } = Input;

const SerchInput = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const search = useSelector(searchReducer);

    // clear search
    useEffect(() => {
        return () => {
            dispatch(updateSearch(''));
        }
    }, []);

    return <Search
        value={search}
        placeholder="Search your favourite restaurant..."
        onChange={e => {
            if (!location.pathname.includes(SEARCH_ROUTE)) {
                navigate(`/${SEARCH_ROUTE}`);
            }
            dispatch(updateSearch(e.target.value.toLocaleLowerCase()));
        }}
    />
}

export default SerchInput;