export const emailRules = [
    {
        type: 'email',
        message: 'The input is not valid E-mail!',
    },
    {
        required: true,
        message: 'Please input your E-mail!',
    },
];

export const passwordRules = [
    {
        required: true,
        message: 'Please input your password!',
    },
];

export const textareaRules = [
    {
        required: true,
        message: 'Please input your message!',
    },
];

const _phoneRegExp = /^\d{10}$/;
export const mobileRules = [
    { required: true, message: 'Please input your mobile number' },
    { pattern: _phoneRegExp, message: 'Please input 10-digit mobile number' },
    { pattern: new RegExp(/^[0-9]+$/), message: 'Please input valie mobile number' }
];
export const nameRules = [
    {
        required: true,
        message: 'Please input your full name!',
        whitespace: true,
    },
];

export const genderRules = [
    {
        required: true,
        message: 'Please select gender!',
    },
];