import { Button } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { currentAuthUserReducer } from "../redux/reducers";
import AuthDialog from "../components/auth/auth-dialog";
import QueriesList from "../components/queries-list";

const Queries = () => {
    const navigate = useNavigate();
    const user = useSelector(currentAuthUserReducer);
    return <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        {user ? <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
            <Button type='primary' onClick={() => navigate(`/contact-us`)}>Raise Query</Button>
            <QueriesList uid={user.uid} />
        </div> : <AuthDialog />}
    </div>
}

export default Queries;