export const ROOT_ROUTE = '/';
export const SEARCH_ROUTE = 'search';
export const CART_ROUTE = 'cart';
export const ORDERS_ROUTE = 'orders';
export const FAVOURITES_ROUTE = 'favourites';
export const PROFILE_ROUTE = 'profile';
export const CATEGORY_ROUTE = 'category';
export const RESTAURANT_ROUTE = 'restaurant';
export const MANAGE_ADDRESS_ROUTE = 'manage-address';
export const MESSAGES_ROUTE = 'messages';
export const QUERIES_ROUTE = 'queries';
export const CONTACTUS_ROUTE = 'contact-us';