import { Card } from "antd";
import Meta from "antd/es/card/Meta";

const CancellationPolicy = () => {
    return <Card size='small'>
        <Meta
            title='Cancellation Policy'
            description='100% cancellation fee will be applicable if you decide to cancel the order anytime after order placement. Avoid cancellation as it leads to food wastage.'
        />
    </Card>
}

export default CancellationPolicy;