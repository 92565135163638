import { Button, Form } from "antd";
import EmailInput from "../inputs/email-input";
import PasswordInput from "../inputs/password-input";
import { loginService } from "../../services/auth-service";

const LoginTab = ({ loading, setLoading, setDialog, messageApi }) => {
    const onFinish = (values) => {
        setLoading(true);
        loginService(values['email'], values['password'])
            .then(_ => {
                messageApi.success('Logged in successfully!');
                setDialog(false);
            }).catch((e) => {
                messageApi.error(e.message);
            }).finally(() => setLoading(false));
    };

    return <Form initialValues={{ remember: true }} onFinish={onFinish}>
        <EmailInput />
        <PasswordInput />
        <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={loading}> Log in </Button>
    </Form>
}

export default LoginTab;