import HeaderMenu from "./header-menu";
import SelectDeliveryAddress from '../address/select-delivery-address';
import { useSelector } from "react-redux";
import { cartRestaurantReducer, currentAuthUserReducer } from "../../redux/reducers";
import AuthDialog from "../auth/auth-dialog";

const AppHeader = ({ path }) => {
    const restaurant = useSelector(cartRestaurantReducer);
    const user = useSelector(currentAuthUserReducer);
    return path.includes('cart') ?
        user !== null ?
            restaurant !== null ? 
            <SelectDeliveryAddress /> :
            <div>My Cart</div>:
            <AuthDialog /> :
        path.includes('category') ?
            <div>Restaurant by Category</div> :
            path.includes('restaurant') ?
                <div>Restaurant Info</div> :
                <HeaderMenu />;

}
export default AppHeader;