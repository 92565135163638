import { collection, onSnapshot, query, where } from "@firebase/firestore";
import { Card, Empty } from "antd";
import Meta from "antd/es/card/Meta";
import { useEffect, useState } from "react";
import { db } from "../utils/firebase-config";

const QueriesList = ({ uid }) => {
    const [lists, setLists] = useState([]);
    useEffect(() => {
        const query1 = query(collection(db, 'queries'), where('id', '==', uid));
        const unsubscribe1 = onSnapshot(query1, (querySnapshot) => {
            const newData = querySnapshot.docs.map((doc) => ({ ...doc.data(), docId: doc.id }));
            setLists(newData);
        }, (error) => {
            console.log(error);
        });
        return () => {
            unsubscribe1();
        }
    }, [uid]);

    return <>
        {
            lists.length > 0 ? lists.map(list => <Card key={list['docId']} size='small'>
                <Meta title={`${list['name']}, ${list['mobile']}`} description={list['message']} />
            </Card>) : <Empty />
        }
    </>
}

export default QueriesList;