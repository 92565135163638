import { Button, Card, Form } from "antd";
import NameInput from "../inputs/name-input";
import GenderInput from "../inputs/gender-input";
import MobileInput from "../inputs/mobile-input";
import { useState } from "react";
import EmailInput from "../inputs/email-input";
import { CloseOutlined, EditFilled } from "@ant-design/icons";
import { currentAuthUserDetailReducer } from "../../redux/reducers";
import { useSelector } from "react-redux";

const UpdateProfile = () => {
    const [loading, setLoading] = useState(false);
    const [update, setUpdate] = useState(false);
    const detail = useSelector(currentAuthUserDetailReducer);
    // update user
    const onFinish = (values) => {
        console.log(values)
    };
    return <Card
        title='Update Profile Info'
        extra={<Button
            type='primary'
            shape='circle'
            size='small'
            danger={update}
            icon={update ? <CloseOutlined /> : <EditFilled />}
            onClick={() => setUpdate(!update)}
        />}
    >
        <Form
            initialValues={{ remember: true }}
            fields={detail && [
                { name: 'name', value: detail['name'] },
                { name: 'gender', value: detail['gender'] },
                { name: 'email', value: detail['email'] },
                { name: 'mobile', value: detail['mobile'] },
            ]}
            onFinish={onFinish}
        >
            <NameInput disabled={!update} />
            <GenderInput disabled={!update} />
            <EmailInput disabled={true} />
            <MobileInput disabled={true} />
            <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={loading} disabled={!update}>Update Profile</Button>
        </Form>
    </Card>
}

export default UpdateProfile;